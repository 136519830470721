import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_HEADING_TYPES, BODY_TYPES } from 'Style/typography';
import { MODAL } from 'Style/modal';

// Utils
import sentenceCase from 'Utils/content/sentence-case';
import SectionUtil from 'Utils/content/section-util';

// Components
import ModalWrapper from 'Webapp/shared/app/modals/modal-wrapper';
import { MODAL_THEMES, MODAL_MODIFIERS } from 'ComponentLibrary/modals/modal';
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import ModalControls from 'Webapp/shared/app/components/modal-controls';
import CustomCheckbox from 'Webapp/shared/app/components/custom-checkbox';
import AuthorAttribution from 'Webapp/shared/app/components/attribution/author';
import AuthorAvatar from 'Webapp/shared/app/components/attribution/author-avatar';

// HOCs
import withT from 'ComponentLibrary/hocs/withT';
import withFollow from 'Webapp/shared/app/hocs/withFollow';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

const Content = styled.div(MODAL.content, { padding: SPACING.BASE4X });
const Controls = styled(ModalControls)({
  borderTop: '0px',
  padding: `${SPACING.LARGE} ${SPACING.BASE4X} ${SPACING.BASE4X}`,
});
const Title = styled.h1(UI_HEADING_TYPES.MEDIUM, MODAL.title, {
  marginBottom: SPACING.BASE4X,
});
const Description = styled.p(BODY_TYPES.LARGE_STANDARD, {
  marginBottom: SPACING.XLARGE,
});
const AttributionWrapper = styled.div({
  marginBottom: SPACING.LARGE,
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: `${SPACING.BASE4X} max-content`,
  gap: `${SPACING.BASE}`,
});

class FollowInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      followSection: !props.isOnboarding,
      followUserSection: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.setSectionFollowed = this.setSectionFollowed.bind(this);
  }

  handleSubmit() {
    const { section, referringUser, handleFollow } = this.props;
    const followSections = [];
    if (this.state.followSection) {
      followSections.push({ ...section, fromUserId: referringUser.userid });
    }
    if (this.state.followUserSection) {
      followSections.push({ ...referringUser, fromInvite: true });
    }
    if (followSections.length > 0) {
      handleFollow({ sections: followSections });
    }

    this.handleDismiss();
  }
  handleDismiss() {
    const {
      dismissModal,
      history,
      routing: { pathname },
      onComplete,
    } = this.props;
    // Load the current page minus the query parameters.
    history.push(pathname);
    dismissModal();
    onComplete();
  }

  setSectionFollowed(key, checked) {
    this.setState({ [key]: checked });
  }
  render() {
    const {
      section,
      referringUser,
      isOnboarding,
      alreadyFollowingSection,
      alreadyFollowingUser,
      t,
    } = this.props;
    const { followSection, followUserSection } = this.state;
    if (!referringUser || !section) {
      return null;
    }

    const decoratedTitle = SectionUtil.getDecoratedSectionTitle(section);
    const title = t('invite_friend_follow_title', {
      referringUser: referringUser.title,
      sectionTitle: decoratedTitle,
    });
    const description = isOnboarding
      ? t('invite_friend_onboard_follow_description', {
          referringUser: '',
          sectionTitle: decoratedTitle,
        })
      : t('invite_friend_follow_description', {
          referringUser: referringUser.title,
          sectionTitle: decoratedTitle,
        });
    return (
      <ModalWrapper
        name="follow-invite-modal"
        modalTheme={MODAL_THEMES.WINDOW}
        modifier={MODAL_MODIFIERS.HAS_NO_HEADER}
      >
        <Content>
          <Title>{title}</Title>
          {isOnboarding && (
            <AttributionWrapper>
              <AuthorAvatar avatarSize={32} author={referringUser} />
              <AuthorAttribution author={referringUser} />
            </AttributionWrapper>
          )}
          <Description>{description}</Description>

          {!isOnboarding && (
            <React.Fragment>
              {!alreadyFollowingSection && (
                <CustomCheckbox
                  name="follow-section"
                  label={decoratedTitle}
                  checked={followSection}
                  onChange={(e) =>
                    this.setSectionFollowed('followSection', e.target.checked)
                  }
                />
              )}
              {!alreadyFollowingUser && (
                <CustomCheckbox
                  name="follow-user-section"
                  label={referringUser.title}
                  checked={followUserSection}
                  onChange={(e) =>
                    this.setSectionFollowed(
                      'followUserSection',
                      e.target.checked,
                    )
                  }
                />
              )}
            </React.Fragment>
          )}
        </Content>
        <Controls fixedControls>
          <Button
            name="topic-customization-cancel"
            styleVariation={StyleVariations.SECONDARY}
            onClick={this.handleDismiss}
          >
            {isOnboarding ? t('skip_for_now') : t('not_now')}
          </Button>
          <Button
            name="topic-customization-done"
            styleVariation={StyleVariations.PRIMARY}
            onClick={this.handleSubmit}
          >
            {sentenceCase(t('follow'))}
          </Button>
        </Controls>
      </ModalWrapper>
    );
  }
}

FollowInvite.propTypes = {
  alreadyFollowingSection: PropTypes.bool,
  alreadyFollowingUser: PropTypes.bool,
  section: PropTypes.object.isRequired,
  referringUser: PropTypes.object.isRequired,
  handleFollow: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
  isOnboarding: PropTypes.bool,
  routing: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onComplete: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

FollowInvite.defaultProps = {
  isOnboarding: false,
  alreadyFollowingSection: false,
  alreadyFollowingUser: false,
};

export default connector(
  connectModal,
  connectRouting,
)(withHistory(withFollow(withT(FollowInvite))));
