import React from 'react';
import classnames from 'classnames';
import CheckmarkIcon from 'ComponentLibrary/icons/checkmark';
import { AppTheme } from 'Webapp/enums';

import connector from 'Utils/connector';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';

/**
 * For accessibility reasons, we should still use a checkbox input, and hide it
 * https://stackoverflow.com/questions/17541614/use-images-instead-of-radio-buttons
 */

interface CustomCheckboxProps {
  name?: string;
  label?: string;
  className?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
  appTheme?: AppTheme;
}

class CustomCheckbox extends React.Component<CustomCheckboxProps> {
  state = {
    isFocused: false,
  };

  handleFocus = () => {
    this.setState({ isFocused: true });
  };

  handleBlur = () => {
    this.setState({ isFocused: false });
  };

  render() {
    const { name, label, className, checked, disabled, onChange, appTheme } =
      this.props;
    const wrapperClassnames = classnames('custom-checkbox', className, {
      'custom-checkbox--disabled': disabled,
    });

    const checkboxImgClassnames = classnames('custom-checkbox__img', {
      'custom-checkbox__img--checked': checked,
      'custom-checkbox__img--focused': this.state.isFocused,
    });

    const labelClassNames = classnames('ui-body--small-standard', {
      'custom-checkbox__label': !disabled,
      'custom-checkbox__label--disabled:': disabled,
    });

    const checkmarkColor = appTheme === AppTheme.DARK ? '#000' : '#fff';
    const checkmarkColorFocused = appTheme === AppTheme.DARK ? '#fff' : '#000';

    return (
      <div className={wrapperClassnames}>
        <label className="custom-checkbox__label-wrapper">
          <input
            type="checkbox"
            name={name}
            className="custom-checkbox__input"
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            disabled={disabled || false}
            checked={checked}
          />
          {checked ? (
            <div className={checkboxImgClassnames}>
              <CheckmarkIcon
                color={
                  this.state.isFocused ? checkmarkColorFocused : checkmarkColor
                }
                size={14}
              />
            </div>
          ) : (
            <div className={checkboxImgClassnames} />
          )}
          {label && <span className={labelClassNames}>{label}</span>}
        </label>
      </div>
    );
  }
}

export default connector<CustomCheckboxProps>(connectTheme)(CustomCheckbox);
